/*
 * -----------------
 * Copyright © 2023 ACK Cyfronet AGH, Poland.
 * -----------------
 */

@import "../node_modules/bootstrap/scss/bootstrap";

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  .header {
    z-index: 20;
    margin-bottom: 20px;

    .logout {
      margin-left: 20px;
    }
  }
  .footer {
    z-index: 20;
  }
  .loadingComponent {
    position: fixed;
    background-color: black;
    opacity: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
}

.horizontal-container {
  display: flex;
  gap: 10px;
  .growing-element {
    flex-grow: 1;
  }
}

.vertical-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-wrapper {
  display: flex; 
  justify-content: center;
  align-items: center;
  height: 100vh; 
  background: #f0f0f0;
}

.login-card-body {
  width: 340px;
}

.login-button-wrapper{
  display: flex; 
  justify-content: flex-end;
}